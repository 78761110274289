import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';


const Profile = () => {
	const navigate = useNavigate();
	const [user_email, setEmail] = useState("")
	const [user_uploads, setUserUploads] = useState("")


	const signOut = () => {
		localStorage.removeItem('cairocodersToken')
		navigate("/");
	}
	useEffect(() => {
		const token = "Bearer " + localStorage.getItem('cairocodersToken')
		axios
			.get(process.env.REACT_APP_API_BASEURL + '/me', {
				headers: {
					'Accept': 'application/json',
					'Authorization': token
				}
			})
			.then(response => {
				setEmail(response.data.email)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])

	useEffect(() => {
		const token = "Bearer " + localStorage.getItem('cairocodersToken')
		axios
			.get(process.env.REACT_APP_API_BASEURL + '/uploads', {
				headers: {
					'Accept': 'application/json',
					'Authorization': token
				}
			})
			.then(response => {
				setUserUploads(response.data.uploads)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])

	return (
		<>
			<div style={{ minHeight: 800, marginTop: 20 }}>
				<p><Link to="/profile" className="btn btn-success">Profile</Link> | <Link to="/upload" className="btn btn-success">Upload</Link>| <button type='button' className="btn btn-success btn-lg" onClick={signOut}>Sign Out</button>  </p>

				<h1>Profile Page</h1>
				<p>Hi and welcome {user_email}</p>
				<p>Your uploads:</p>

				<ul>
					<table>
						<tbody>
							<tr>
								<th>Filename</th>
								<th>Secret</th>
							</tr>
							{user_uploads.length ? user_uploads.map((upload, i) =>
								<tr key={i}>
									<td>{upload[0]}</td>
									<td>{upload[2]}</td>
									<td><input value={process.env.REACT_APP_BACKEND_BASEURL + "/" + upload[1]} disabled readOnly></input></td>
								</tr>

							) : null}
						</tbody>
					</table>
				</ul>
			</div>

		</>
	)
}
export default Profile