import React, { } from 'react';
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './components/Login.js'
import Profile from './components/Profile.js'
import Register from './components/Register.js'
import Upload from './components/Upload.js'
import { RequireToken } from './components/Auth.js'

function App() {
	return (
		<div className="vh-100 gradient-custom">
			<div className="container">
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Login />} />
						<Route
							path="/profile"
							element={
								<RequireToken>
									<Profile />
								</RequireToken>
							}
						/>
						<Route path="/register" element={<Register />} />
						<Route path="/upload"
							element={<RequireToken>
								<Upload />
							</RequireToken>
							}
						/>
					</Routes>
				</BrowserRouter>
			</div>
		</div>
	);
}

export default App;