import React, { useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { fetchToken } from './Auth.js'
const qs = require('qs');

export default function Login() {
	//const navigate = useNavigate();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [infotext, setInfotext] = useState("");

	const handleSubmit = () => {
		if (username.length === 0) {
			alert("Username has left Blank!");
		}
		else if (password.length === 0) {
			alert("password has left Blank!");
		}
		else {
			console.log('axios')
			let data = qs.stringify({
				'email': username,
				'password': password
			});
			axios({
				method: 'POST',
				url: process.env.REACT_APP_API_BASEURL + '/register',
				data: data,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/x-www-form-urlencoded'
				},
			})
				.then(function (response) {
					console.log(response);
					console.log(response["status"])
					//console.log(response.data);
					//alert(response.data["message"])
					if (response.data["message"] === "Login failed") {
						alert("Login failed");
					}
					else {
						if (response["status"] === 201) {
							console.log("Rekisteröinti onnistui")
							setInfotext("Rekisteröinti onnistui!")
						}
					}
				})
				.catch(function (error) {
					console.log(error, 'error');
				});
		}
	}
	return (
		<div>
			<div className="mask d-flex align-items-center h-100 gradient-custom-3">
				<div className="container h-100">
					<div className="row d-flex justify-content-center align-items-center h-100">
						<div className="col-12 col-md-9 col-lg-7 col-xl-6">
							<div className="card">
								<div className="card-body p-5">
									<p>{infotext}</p>
									{
										fetchToken()
											? (
												<p>You are logged in!</p>
											)
											: (
												<p>Register account!</p>
											)
									}
									<form>

										<div className="form-outline mb-4">
											<label className="form-label">Email</label>
											<input type="text" className="form-control form-control-lg" name="username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
										</div>

										<div className="form-outline mb-4">
											<label className="form-label">Your Password</label>
											<input type="password" className="form-control form-control-lg" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
										</div>


										<div className="d-flex justify-content-center">
											<input type="button" className="btn btn-success btn-lg" name="submit" id="submit" value="Register" onClick={handleSubmit} /><Link to="/" className="btn btn-success">Login</Link>
										</div>

									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}