import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import { setToken, fetchToken } from './Auth.js'
const qs = require('qs');


export default function Login() {
	const navigate = useNavigate();
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [errormessage, setErrorMessage] = useState("")

	const handleSubmit = () => {
		if (username.length === 0) {
			setErrorMessage("Username has left Blank!")
			setTimeout(setErrorMessage(""), 5000)
			//alert("Username has left Blank!");
		}
		else if (password.length === 0) {
			setErrorMessage("Password has left Blank!")
			setTimeout(setErrorMessage(""), 5000)
			//alert("password has left Blank!");
		}
		else {
			let data = qs.stringify({
				'username': username,
				'password': password
			});
			console.log('axios')
			axios({
				method: 'POST',
				url: process.env.REACT_APP_API_BASEURL + '/login',
				data: data,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/x-www-form-urlencoded'
				},
			})
				.then(function (response) {
					console.log(response);
					//console.log(response.data);
					//alert(response.data["message"])
					if (response.data["message"] === "Login failed") {
						alert("Login failed");
					} else {
						if (response.data.access_token) {
							setToken(response.data.access_token)
							navigate("/profile");
						}
					}
				})
				.catch(function (error) {
					console.log(error, 'error');
				});
		}
	}
	return (
		<div>
			<div className="mask d-flex align-items-center h-100 gradient-custom-3">
				<div className="container h-100">
					<div className="row d-flex justify-content-center align-items-center h-100">
						<div className="col-12 col-md-9 col-lg-7 col-xl-6">
							<div className="card">
								<div className="card-body p-5">
									{
										fetchToken()
											? (
												<p>You are logged in!</p>
											)
											: (
												<p>Login Account!</p>
											)
									}
									<p>{errormessage}</p>
									<form>

										<div className="form-outline mb-4">
											<label className="form-label">Your User Name</label>
											<input type="text" className="form-control form-control-lg" name="username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
										</div>

										<div className="form-outline mb-4">
											<label className="form-label">Your Password</label>
											<input type="password" className="form-control form-control-lg" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
										</div>


										<div className="d-flex justify-content-center">
											<input type="button" className="btn btn-success btn-lg" name="submit" id="submit" value="Login" onClick={handleSubmit} /><Link to="register" className="btn btn-success">Register</Link>
										</div>

									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}